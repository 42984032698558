.header-container{
    width: 100%;
    background-color: var(--theme-ui-colors-primary,#4a266a);
    background-color: var(--theme-ui-colors-primary,#421640);
    color: #fff;
    height: 50px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    font-family: Cinzel Decorative ,serif;
    font-weight: bold;
    justify-content: space-between;
}

.blacksun {
    background: #fff;
    border-radius: 50px;
}

button:disabled, button[disabled] {
    background-color: grey;
}

body {
    background: #333;
}